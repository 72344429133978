/* eslint-disable */

import WS from "@/utils/ws";
import { setLocalStorage, removeLocalStorage } from "@/utils/local-storage";

var IM = {
	Conn:null,
	userInfo:null,
	rooms:[],
	eventRoomChange:null,
	init: function(UserInfo,iteIds,initCB)
	{
		this.Conn = new WS();
		this.userInfo = UserInfo;

	    let RoomsInfo = this.getLocalStorage("RoomsInfo_" + this.userInfo.id);
		this.Conn.init(UserInfo,iteIds,() => {
			if (RoomsInfo) {
				let roomsJson = JSON.parse(RoomsInfo)
		    	for (var i = 0; i < roomsJson.length; i++) {
                    let room = roomsJson[i];
                    if (!room.lastMessage) {
                        continue;
                    }
		    		this.updateRoomInfo(room)
		    	}
		    }
		});
		this.initEvent();
		initCB();
	},

	initEvent:function()
	{

		this.Conn.AddListener("enter",(data)=>{
            this.Conn.RoomInfo(data.room,(roomInfo)=>{
                this.updateRoomInfo(roomInfo);
            });
        });

		this.Conn.AddListener("quit",(data)=>{
            this.Conn.RoomInfo(data.room,(roomInfo)=>{
                this.updateRoomInfo(roomInfo);
            });
        });

        this.Conn.AddListener("notify",(msg) => {
            let arrMsg = msg.message.split(":");
            if (arrMsg[0] == "new") {
                this.Conn.Enter(arrMsg[1],(roomInfo) => {
                    this.updateRoomInfo(roomInfo);
                });
            } else if (arrMsg[0] == "reload") {
                this.Conn.Enter(arrMsg[1],(roomInfo) => {
                	this.updateRoomInfo(roomInfo);
                });
            }
        });
        this.Conn.AddListener("talk",(data) =>{
            this.loadConversations(data);
        });

        this.Conn.AddListener('image', (data) => {
            this.loadConversations(data);
        });
        this.Conn.AddListener('file', (data) => {
            this.loadConversations(data);
        });
        this.Conn.AddListener('video', (data) => {
            this.loadConversations(data);
        });
        this.Conn.AddListener('audio', (data) => {
            this.loadConversations(data);
        });
	},

	updateRoomInfo(roomInfo)
    {
        if (roomInfo == null) {return;}
        let arrItem = this.rooms;
        let isFound = false;

        for (let j = 0; j < arrItem.length; j++) {
            if (arrItem[j].name == roomInfo.name) {  // 找到旧房间，保存旧房间聊天记录，其他不再保存
                // roomInfo.lastTime = TimeUtil.formatChatTime(roomInfo.createTime);
                roomInfo.timeRead = arrItem[j].timeRead;
                arrItem[j] = roomInfo;
                isFound = true;
                break;
            }
        }
        if (!isFound) {
            // roomInfo.lastTime = TimeUtil.formatChatTime(roomInfo.createTime);
            arrItem.push(roomInfo);
        }

        // DB.saveRoomInfo(roomInfo);


        if (roomInfo.lastMessage) {
            roomInfo.dateF = IM.formatChatTime(roomInfo.lastMessage.dateTime);
        } else {
        	roomInfo.dateF = IM.formatChatTime(roomInfo.activeTime);
        }
        
        this.sortData();
        // let keyUser = "RoomsInfo"+this.userInfo.id;
        let infoRoomUser = "RoomsInfo_" + this.userInfo.id;
		let keySave = {};
		keySave[infoRoomUser] = JSON.stringify(arrItem);
        setLocalStorage(keySave);
        if (this.eventRoomChange) {
        	this.eventRoomChange();
        }
        // this.setState({ recentConversation: arrItem});
    },

    removeRoom(roomName)
    {
        let arrItem = [];
        var hasChange = false;
        for (let j = 0; j < this.rooms.length; j++) {
            if (this.rooms[j].name == roomName) {  
                hasChange = true;
            } else {
                arrItem.push(this.rooms[j]);
            }
        }

        if (hasChange) {
            let infoRoomUser = "RoomsInfo_" + this.userInfo.id;
            let keySave = {};
            this.rooms = arrItem;
            keySave[infoRoomUser] = JSON.stringify(arrItem);
            setLocalStorage(keySave);
            if (this.eventRoomChange) {
                this.eventRoomChange();
            }
        }
    },

    idUpdate: -1,
    // 加载当前用户的会话
    loadConversations(itemMsg) {

        let arrItem = this.rooms;

        let roomInfo;
        for (let j = 0; j < arrItem.length; j++) {
            roomInfo = arrItem[j];
            if (roomInfo.name == itemMsg.room) {
                break;
            }
        }
        if (roomInfo) {
            if (roomInfo.lastMessage) {
                if (roomInfo.lastMessage.msgId < itemMsg.msgId) {
                    roomInfo.lastMessage = itemMsg;
                    if (itemMsg.user.type == 1) {
                    	roomInfo.Badge = 1;
                    }
                }
            } else {
                roomInfo.lastMessage = itemMsg;
                if (itemMsg.user.type == 1) {
                  	roomInfo.Badge = 1;
                }
                
            }
        }
        clearTimeout(this.idUpdate);
        this.idUpdate = setTimeout(() => {
            this.sortData();
            if (this.eventRoomChange) {
	        	this.eventRoomChange();
	        }
        }, 20);
    },

    sortData()
    {
        let arrItem = this.rooms;
        let arrOnlineRooms = [];
        let arrOfflineRooms = [];
        for (let i = 0; i < arrItem.length; i++) {
            let room = arrItem[i]
            let isOnline = false;
            for (let i = 0; i < room.onLine.length; i++) {
                if (room.onLine[i].type == 1) {
                    isOnline = true;
                }
            } 
            if (isOnline) {
                arrOnlineRooms.push(room);
            } else {
                arrOfflineRooms.push(room);
            }
        }
        let arrOnlineRooms2 = arrOnlineRooms.sort(function(roomA,roomB){ 
            let timeOrderA = roomA.activeTime;
            let timeOrderB = roomB.activeTime;

            if (roomA.lastMessage) {
                timeOrderA = roomA.lastMessage.dateTime;
            }
            if (roomB.lastMessage) {
                timeOrderB = roomB.lastMessage.dateTime;
            }
            return new Date(timeOrderB) - new Date(timeOrderA);// 降序
        });

        let arrOfflineRooms2 = arrOfflineRooms.sort(function(roomA,roomB){ 
            let timeOrderA = roomA.activeTime;
            let timeOrderB = roomB.activeTime;

            if (roomA.lastMessage) {
                timeOrderA = roomA.lastMessage.dateTime;
            }
            if (roomB.lastMessage) {
                timeOrderB = roomB.lastMessage.dateTime;
            }
            return new Date(timeOrderB) - new Date(timeOrderA);// 降序
        });
        this.rooms = arrOnlineRooms2.concat(arrOfflineRooms2);
    },
	log: function(o)
	{
		console.log(o);
	},
	format:function(date, fmt) {
	    var o = {
	        "M+": date.getMonth() + 1,                 //月份
	        "d+": date.getDate(),                    //日
	        "h+": date.getHours(),                   //小时
	        "m+": date.getMinutes(),                 //分
	        "s+": date.getSeconds(),                 //秒
	        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
	        "S": date.getMilliseconds()             //毫秒
	    };
	    if (/(y+)/.test(fmt)) {
	        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	    }
	    for (var k in o) {
	        if (new RegExp("(" + k + ")").test(fmt)) {
	          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	        }
	    }
	    return fmt;
	},
	formatChatTime:function (timestamp) {
	    return this.format(new Date(timestamp), 'MM月dd日 hh:mm:ss');
	},
	getLocalStorage:function(key){
	 	let storage = window.localStorage.getItem(key)
	  	return storage;
	}
}
export default IM;
