/* eslint-disable */
import IM from "@/utils/im";
export default class WS
{
    constructor() {
        this.conn = null;
        this.wsReqIndex = 1;
        this.callBack = {};
        this.onOpenConn = null;
        this.idBeepFun = -1;
        this.loginInfoSave = null;
        this.serverInfo = null;
        this.ppIndex = 0;
    }

    init(loginInfo,server,fn)
    {
    	if (loginInfo == null) {
    	}
    	// this.log('没有登录信息， 停止链接Socket服务器');
    	// debugger;
    	// return;
        this.connectServer(loginInfo,server,fn);
        this.idTimeoutConnect = setTimeout(()=>{
            if (!this.conn || WebSocket.OPEN != this.conn.readyState) {
                this.closeConn();
                this.reConn(); 
            } 
        },5000);
    }
    
    connectServer(loginInfo,serverInfo,fn){
        this.log('------  connectServer ------')
        this.log(loginInfo)
        this.onOpenConn = fn;
        let ws = null;
        
        let url = 'wss://im.jinlaijinwang.com:19090/?token=';
        // let url = 'ws://im.jinlaijinwang.com:18899/?token=';
        // let url = 'ws://127.0.0.1:5000/?token=';
        this.log(url);
        ws = new WebSocket(url + loginInfo.access_token || '');
        // ws.addEventListener('open',(e)=>{
        //     this.onopen(e);
        // });
        // ws.addEventListener('message',(e)=>{
        //     this.onmessage(e);
        // });
        // ws.addEventListener('error',(e)=>{
        //     this.onerror(e);
        // });
        // ws.addEventListener('close',(e)=>{
        //     this.onclose(e);
        // });
        ws.onmessage = this.onmessage.bind(this);
        ws.onclose = this.onerror.bind(this);
        ws.onerror = this.onclose.bind(this);
        ws.onopen = this.onopen.bind(this);
        this.conn = ws;
        this.loginInfoSave = loginInfo;
        this.serverInfo = serverInfo;
    }

    initServerAction()
    {
    	this.Login(this.loginInfoSave.uid, 2, this.serverInfo, (dataLogin) => {
            // this.ChangeState("1", () => {
                this.Rooms((listRoom) => {
                    for (var i = listRoom.length - 1; i >= 0; i--) {
                        let roomId = listRoom[i];
                        this.RoomInfo(roomId,function(roomInfo){
                            IM.updateRoomInfo(roomInfo)
                        });
                    }
                });
            // });
        });
    }

    // Websocket接口：
    Login (name, type, serviceId, callBack,) {
        let pLogin = {'url': '/Login','params': {'state':1,'serviceId': serviceId, 'name': name, 'type': type,'device':3,deviceinfo:'default'},}
        this.send(pLogin, callBack);
    }

    ChangeState(state, callBack) {
        let params = {'url': '/ChangeState','params': {'state': state},};
        this.send(params, callBack);
    }

    Rooms(fn) {
        let p = {'url': '/Rooms','params': {},};
        this.send(p, fn);
    }

    onServerList = [];
    __idOnServerList = 0;
    AddListener (type, fn) {
        let id = this.__idOnServerList++;
        this.onServerList.push({'type': type, 'fn': fn, id: id});
        return id;
    }
    removeListener(id) {
        let indexRemove = -1;
        for (var i = 0; i < this.onServerList.length; i++) {
            let l = this.onServerList[i];
            if (l.id == id) {
                indexRemove = i;
                break;
            }
        }

        if (indexRemove > -1) {
            this.onServerList.splice(indexRemove, 1);
        }
    }
    RoomInfo(roomId, fn)
    {
        this.send({'url': '/RoomInfo','params': {'room': roomId},}, fn);
    }
    //return list
    CreateRoom (serviceId, fn) {
        let pLogin = {'url': '/CreateRoom','params': {'serviceId': '0'},};
        this.send(pLogin, fn);
    }
    //return roomName
    Enter (room, fn) {
        this.send({'url': '/Enter','params': {'room': room},}, fn);
    }

    Exit (room) {
        let pLogin = {'url': '/Exit','params': {'room': room},};
        this.send(pLogin);
    }

    Blocking (room, isIP, timeLimit, fn) {
        let p = {'url': '/Blocking','params': {'name': room, 'ip':isIP, 'limit':timeLimit},};
        this.send(p, fn);
    }

    Unblocking (name,serviceId, fn)
    {
        let p = {'url': '/Unblocking','params': {'name': name,'serviceId':serviceId}};
        this.send(p, fn);
    }

    BlackList(serviceId,fn)
    {   
        let p = {'url': '/BlackList','params': {serviceId:null}};
        this.send(p, fn);
    }


    Talk (room, message, fn) {
        let p = {'url': '/Talk','params': {'room': room, 'message': message},};
        this.send(p, fn);
    }

    Image (room, image, fn) {
        let pLogin = {'url': '/Image','params': {'room': room, 'image': image},};
        this.send(pLogin, fn);
    }

    Video (room, file, fn) {
        let pLogin = {'url': '/Video','params': {'room': room, 'video': file},};
        this.send(pLogin, fn);
    }

    Audio (room, file, fn) {
        let pLogin = {'url': '/Audio','params': {'room': room, 'audio': file},};
        this.send(pLogin, fn);
    }

    File (room, file, fn) {
        let pLogin = {'url': '/File','params': {'room': room, 'file': file},};
        this.send(pLogin, fn);
    }

    ChatLog (roomId, fn) {
        this.send({'url': '/ChatLog', 'params': {'room': roomId}}, fn);
    }

    ChatLogWithId (roomId,msgId, fn) {
        this.send({'url': '/ChatLog', 'params': {'room': roomId,'msgId':msgId}}, fn);
    }

    Read(roomId,msgId,fn) {
        this.send({'url': '/Read', 'params': {'room': roomId,'msgId':msgId}}, fn);
    }

    ServiceUsers(state, callBack) {
        let params = {'url': '/ServiceUsers','params': {'state': state},};
        this.send(params, callBack);
    }

    LoginServerActions()
    {
    	this.Login(this.loginInfoSave.uid, 2, this.serverInfo, (dataLogin) => {
	        this.ChangeState(1, () => {
	            this.Rooms((listRoom) => {
	                for (let i = listRoom.length - 1; i >= 0; i--) {
	                    let roomId = listRoom[i];
	                    this.Enter(roomId);
	                }
	            });
	        });
	    });
    }

    onopen(){
    	var that = this;
        this.log('------连接成功  onopen------')
        if (this.onOpenConn) {
            this.onOpenConn();
            this.onOpenConn = null;
        } else {
            this.log('----重连操作：LoginServer----');  
        }

        this.initServerAction();
        clearInterval(this.idBeepFun);
        this.idBeepFun = setInterval((function(){
            if (this.ppIndex > 2) {
                this.closeConn();
                this.reConn(); 
                this.ppIndex = 0;
                return;
            }
            this.conn.send('ping');
            this.ppIndex++;
        }).bind(this),5000);
        this.countTimesReconn = 0;
        this.reConnCheckInterval();
    }

    onmessage(e){
        if (e.data) {
            if (e.data == 'ping') {
                if (this.conn && WebSocket.OPEN == this.conn.readyState) {
                    this.conn.send('pong')    
                }
                return;
            }
            if (e.data == 'pong') {
                // this.conn.send('ping')
                this.beep();
                return;
            }
            this.log('------ onmessage ------')
            // this.log(e);
            let data = JSON.parse(e.data);

            if (data.id) { //  请求收消息
                this.log('服务器回应:有请求id，onmessage由：id = ' + data.id )
                if (e.data.length < 10000) this.log(data)
                let idFn = data.id;
                if (this.callBack[idFn] != null) {
                    if ((data.url == "/Talk" || data.url == "/Image" || data.url == "/Video" || data.url == "/Audio" || data.url == "/File")
                     && data.code == 201) {
                        this.callBack[idFn](data);
                        this.callBack[idFn] = null;
                    } else {
                        this.callBack[idFn](data.data,data);
                        this.callBack[idFn] = null;
                    }
                }
            } else { //  服务器主动下发消息
                this.log('服务器下发:无请求id， onmessage: ')
                if (e.data.length < 10000) {
                    this.log(data)
                } else {
                    // this.log(data)
                }
                
                this.OnServer(data);
            }
        } else {
            this.log('------ onmessage 出错------')
            this.log(e);
        }
        this.log('')
    }

    onerror(e){
        this.log('------状态 onerror------')
        this.log(e);
        if (e.data) {
            let data = JSON.parse(e.data);
            let idFn = data.id;
            if (this.callBack[idFn] != null) {
                this.callBack[idFn](data);
                this.callBack[idFn] = null;
            }
        }
        clearInterval(this.idBeepFun);
        // NativeModules.RN2NativeModule.toast('连接错误');
    }

    onclose(e){
        this.log('------ onclose ------')
        this.log(e.code, e.reason);
        clearInterval(this.idBeepFun);
        // NativeModules.RN2NativeModule.toast('连接断开');
    }

    send(data,fn){
        this.wsReqIndex++;
        this.log('------ send------ id = ' + this.wsReqIndex)

        if (this.wsReqIndex > 2000) {
            this.wsReqIndex = 1;
        }
        data["params"]._requestid = this.wsReqIndex;

        if (fn) {
            this.callBack[data["params"]._requestid] = fn;
        }

        let dataSend = JSON.stringify(data);
         if (dataSend.length < 10000) this.log(dataSend)
       
    	if (this.conn && WebSocket.OPEN == this.conn.readyState) {
    		this.conn.send(dataSend);
        } else {
        	this.log('------ send 错误-- 抛弃请求:' + this.wsReqIndex);
        }
        this.log('------ send end-- ' + this.wsReqIndex);
    }

    // 每次重连次数为20(countTimesReconn)次
    // 每5秒关闭并连接一次Server
    countTimesReconn = 0;
    idTimeoutConnect = -1;
    reConn()
    {
        if (this.conn && WebSocket.OPEN == this.conn.readyState) {
            // this.closeConn(); // 连接存在,不断开重连了
            // NativeModules.RN2NativeModule.toast('已经连接到服务器');
            return;
        }
        if (this.conn && WebSocket.CONNECTING == this.conn.readyState) {
            // this.closeConn(); // 连接正在继续,不断开重连了
            // NativeModules.RN2NativeModule.toast('正在连接服务器');
            return;
        }
        if (this.countTimesReconn > 50) {
            // NativeModules.RN2NativeModule.toast('重连次数过多,请稍后重试');
            return; // 一组重连操作不再继续
        }
        
        // this.conn = null;
        this.countTimesReconn++;
        this.connectServer(this.loginInfoSave,this.serverInfo);
        // NativeModules.RN2NativeModule.toast('正在尝试重新连接服务器');
        clearInterval(this.idTimeoutConnect); // 重连过程，清除已有监听队列，再重新排队
        this.idTimeoutConnect = setTimeout(()=>{
            if (!this.conn || WebSocket.OPEN != this.conn.readyState) {
                this.closeConn();
                this.reConn(); 
            } 
        },5000);
    }

    // 每次连接成功后，则自动开启此检查，当断掉连接时不断重连Server，频率1次/秒
    idIntervalReconnCheck = -1;
    reConnCheckInterval()
    {
        clearInterval(this.idIntervalReconnCheck);
        this.idIntervalReconnCheck = setInterval(()=>{
            if (!this.conn || WebSocket.OPEN != this.conn.readyState) {
                this.countTimesReconn = 0;
                this.closeConn();
                this.reConn();
                clearInterval(this.idIntervalReconnCheck); // 重连：清除每秒监听,成功连接onopen后会添加新监听的
            }
        },1000);
    }

    beep() {
        // if (this.conn == null || WebSocket.OPEN != this.conn.readyState) {
        //     this.onClose("连接已经断开");
        // }
        this.ppIndex--;
        this.log('收到心跳,this.ppIndex = ' + this.ppIndex);
        // this.conn.send("pone");
    }

    closeConn() {
        if (this.conn != null) {
            this.conn.close();
        }
        clearInterval(this.idBeepFun);
        this.conn = null;
    }

    closeConnByHand()
    {
        clearInterval(this.idIntervalReconnCheck);
        clearInterval(this.idTimeoutConnect);
        if (this.conn != null) {
            this.conn.close();
        }
        this.conn = null;
    }

    log(arg){
        // console.log('');
        // console.log(new Date());
        console.log(arg);
    }

    OnServer(data){
    	 for (var i = 0; i < this.onServerList.length; i++) {
            let obj = this.onServerList[i];
            if (obj.type == data.type) {
                obj.fn(data);
            }
        }
    }
}