<template>
  <el-tabs
    v-model="editableTabsValue"
    type="card"
    closable
    @tab-click="clickTab"
    @tab-remove="removeTab"
  >
    <el-tab-pane
      v-for="tab in editableTabs"
      :key="tab.name"
      :label="tab.title"
      :name="tab.name"
      class="message-list-box"
    >
      <ul :ref="'ul_' + tab.name" class="message-list">
        <li
          v-for="(item, index) in tab.content"
          :key="index"
          :class="{ 'isMyMsg': item.isMyMsg ,'message':true}"
        >
          <div class="message-user-pic">
            <img src="../assets/client.png" v-if="!item.isMyMsg" />
            <img src="../assets/user.png" v-if="item.isMyMsg" />
          </div>
          <div class="message-paenl">
            <h5>
              <!-- <span class="label label-success">[{{ item.room }}]</span> -->
              <span class="label label-info" style="font-size:12px" v-if="!item.isMyMsg">{{"访客"}}</span>
              <span class="label label-default">{{ item.dateF }}</span>
            </h5>
             <div v-if="item.type == 'talk' && item.isMyMsg" style="text-align:right">{{ item.message }}</div>
            <div v-else-if="item.type == 'image'">
              <img style="max-height:512px;max-width:512px;" 
                :src="baseUrl + item.message.split('|')[0]"
                :alt="item.message.split('|')[1]"
              />
            </div>
            <div v-else-if="item.type == 'file'">
              <a
                :href="baseUrl + item.message.split('|')[0]"
                target="_blank"
              >{{ item.message.split("|")[1] }}</a>
            </div>
            <div v-else-if="item.type === 'audio'">
              <audio controls="controls" style="max-width:40%;max-height:200px;">
                <source :src="baseUrl + item.message.split('|')[0]" type="audio/aac" />
              </audio>
            </div>
            <div v-else-if="item.type === 'video'">
              <video style="max-width:40%;max-height:200px;" controls>
                <source :src="baseUrl + item.message.split('|')[0]" type="video/mp4" />
              </video>
            </div>
            <div v-else>{{ item.message }}</div>
          </div>
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import IM from "@/utils/im";
export default {
  name: "MessageContent",
  props: {
    editableTabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editableTabsValue: "",
      baseUrl: this.$baseURL
    };
  },
  watch: {
    editableTabs: {
      handler: function() {
        let that = this;
        setTimeout(() => {
          if (that.editableTabsValue === "") {
            return;
          }

          let box = that.$refs["ul_" + that.editableTabsValue][0];
          box.scrollTop = box.scrollHeight;
        }, 200);
      },
      deep: true
    }
  },
  methods: {
    clickTab(tab) {
      this.$emit("select", tab.name);
    },
    addTab(roomInfo) {
      this.editableTabsValue = roomInfo.name;
      this.editableTabs.push({
        title: roomInfo.description,
        name: roomInfo.name,
        content: []
      });
    },
    removeTab(tabName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === tabName) {
        tabs.forEach((tab, index) => {
          if (tab.name === tabName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            } else {
              activeName = "";
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.$emit("updateContent", {
        editableTabsValue: activeName,
        editableTabs: tabs.filter(tab => tab.name !== tabName),
        removeTab: tabName
      });
    }
  }
};
</script>

<style>
.el-tabs__content {
  overflow-y: auto;
}
.message-list {
  list-style: none;
  overflow: auto;
  margin: 0px;
  padding: 20px;
  height: calc(100vh - 344px);
  box-sizing: border-box;
}
.message-list .message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.message-list .message .message-paenl {
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1.6;
}
.message-list .message.isMyMsg {
  flex-direction: row-reverse;
}
.message-list .message .message-paenl {
  max-width: 50%;
  background: #366ee1;
  border-radius: 7px 7px 7px 0;
  margin-left: 15px;
  color: #fff;
  text-align: left;
}
.message-list .message.isMyMsg .message-paenl {
  background: #f3f5f8;
  border-radius: 7px 7px 0px 7px;
  margin-right: 15px;
  color: #686b79;
  text-align: left;
}
.el-tabs--card > .el-tabs__header {
  border: 0;
  margin: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.message-user-pic img {
  width: 50px;
}
.message-list .message h5 {
  margin: 0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}
.message-list .message .label-default {
  font-size: 12px;
}
</style>
