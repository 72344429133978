import request from "@/utils/request";

function beetlexWebSocket() {
  this.baseUrl = "http://localhost:18080";
  this.token = null;
  let _this = this;
  this.wsUri = {
    get: function() {
      let param = "?type=visitor";
      if (_this.token) param = "?token=" + _this.token;
      return _this.baseUrl.replace("http", "ws") + param;
    }
  };
  this.websocket = null;
  this.status = false;
  this.messagHandlers = {};
  this.timeout = 2000;
  this.receive = null;
  this.disconnect = null;
  this.connected = null;
  this.reconnect = true;
}

beetlexWebSocket.prototype.send = function(url, params, callback) {
  if (this.status === false) {
    if (callback != null) {
      callback({ Url: url, Code: 505, Error: "disconnect" });
    }
  }
  this.messagHandlers[params._requestid] = callback;
  let data = { url: url, params: params };
  this.websocket.send(JSON.stringify(data));
};

beetlexWebSocket.prototype.onOpen = function(evt) {
  console.log(evt);

  this.status = true;
  if (this.connected) this.connected();
};

beetlexWebSocket.prototype.onClose = function(evt) {
  this.status = false;
  let _this = this;
  if (this.disconnect) this.disconnect();
  if (!this.reconnect) return;
  if (evt.code === 1006) {
    setTimeout(function() {
      _this.connect();
    }, _this.timeout);
    if (_this.timeout < 10000) _this.timeout += 1000;
  }
};

beetlexWebSocket.prototype.onMessage = function(evt) {
  let data = evt.data;
  if (data === "ping") {
    this.websocket.send("pong");
    return;
  }

  let msg = JSON.parse(data);
  let callback = this.messagHandlers[msg.id];
  if (callback) callback(msg);
  else if (this.receive) {
    if (msg.data !== null && msg.data !== undefined) this.receive(msg.data);
    else this.receive(msg);
  }
};

beetlexWebSocket.prototype.onError = function(evt) {
  console.log(evt);
};

beetlexWebSocket.prototype.connect = function() {
  this.websocket = new WebSocket(this.wsUri.get());
  let _this = this;
  this.websocket.onopen = function(evt) {
    _this.onOpen(evt);
  };
  this.websocket.onclose = function(evt) {
    _this.onClose(evt);
  };
  this.websocket.onmessage = function(evt) {
    _this.onMessage(evt);
  };
  this.websocket.onerror = function(evt) {
    _this.onError(evt);
  };
};

beetlexWebSocket.prototype.close = function() {
  this.reconnect = false;
  if (this.status) {
    this.websocket.close();
  }
};

function beetlex4axios() {
  this._requestid = 0;
  this.websocket = new beetlexWebSocket();
}

beetlex4axios.prototype.useWebsocket = function(host, token) {
  if (host) this.websocket.baseUrl = host;
  if (token) this.websocket.token = token;
  this.websocket.reconnect = true;
  this.websocket.connect();
};

beetlex4axios.prototype.getRequestID = function() {
  this._requestid++;
  if (this._requestid > 2000) {
    this._requestid = 1;
  }

  return this._requestid;
};

beetlex4axios.prototype.get = function(url, params, callback) {
  let httpUrl = this.websocket.baseUrl + url;
  if (!params) params = {};
  params["_requestid"] = this.getRequestID();

  if (this.websocket.status === true) {
    this.send(url, params)
      .then(data => {
        callback(data);
      })
      .catch(err => {
        this.onError(err.code, err.error);
      });
  } else {
    let getApi = function() {
      return request({ url: httpUrl, method: "get", params: params });
    };
    getApi()
      .then(res => {
        this.dataProcess(res.data)
          .then(data => {
            callback(data);
          })
          .catch(err => {
            this.onError(err.code, err.error);
          });
      })
      .catch(function(error) {
        let code = error.response ? error.response.status : 500;
        let message = error.message;
        if (error.response) message += "\r\n" + error.response.data;
        this.onError(code, message);
      });
  }
};

beetlex4axios.prototype.onError = function(code, message) {
  alert(message);
};

beetlex4axios.prototype.post = function(url, params, callback) {
  let httpUrl = this.websocket.baseUrl + url;
  if (!params) params = {};
  params["_requestid"] = this.getRequestID();

  if (this.websocket.status === true) {
    this.send(url, params)
      .then(data => {
        callback(data);
      })
      .catch(err => {
        this.onError(err.code, err.error);
      });
  } else {
    let postApi = function() {
      return request({
        url: httpUrl,
        method: "post",
        data: JSON.stringify(params)
      });
    };
    postApi()
      .then(res => {
        this.dataProcess(res.data)
          .then(data => {
            callback(data);
          })
          .catch(err => {
            this.onError(err.code, err.error);
          });
      })
      .catch(function(error) {
        let code = error.response ? error.response.status : 500;
        let message = error.message;
        if (error.response) message += "\r\n" + error.response.data;
        this.onError(code, message);
      });
  }
};

beetlex4axios.prototype.send = function(url, params) {
  let _this = this;
  return new Promise(function(resolve, reject) {
    _this.websocket.send(url, params, data => {
      _this
        .dataProcess(data)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  });
};

beetlex4axios.prototype.dataProcess = function(data) {
  return new Promise(function(resolve, reject) {
    if (data.code && data.code !== 200) {
      reject(data);
    } else if (data.data !== null && data.data !== undefined) {
      resolve(data.data);
    } else {
      resolve(data);
    }
  });
};

const beetlex = new beetlex4axios();

function beetlexAction(actionUrl, actionData) {
  this.url = actionUrl;
  this.data = actionData;
  this.requesting = null;
}

beetlexAction.prototype.onValidate = function(data) {
  if (this.requesting) return this.requesting(data);
  return true;
};

beetlexAction.prototype.get = function(data) {
  let _this = this;
  let _postData = this.data;
  if (data) _postData = data;
  return new Promise(function(resolve, reject) {
    if (!_this.onValidate(_postData)) reject();
    beetlex.get(_this.url, _postData, r => resolve(r));
  });
};

beetlexAction.prototype.post = function(data) {
  let _this = this;
  let _postData = this.data;
  if (data) _postData = data;
  return new Promise(function(resolve, reject) {
    if (!_this.onValidate(_postData)) reject();
    beetlex.post(_this.url, _postData, r => resolve(r));
  });
};

export { beetlex, beetlexAction };
