<template>
  <el-container class="main-box">
    <header>
      <div class="top-container">
        <div class="user-sataus-panel">
          <div class="lf">
            <img src="..\assets\profile-picture.png" alt />
          </div>
          <div class="rt">
            <div class="txt">客服状态</div>
            <el-dropdown trigger="click" @command="handleCommand">
              <el-tag class="el-dropdown-link drop-panel">
                <el-tag :type="stateType" class="state-row">
                  <i class="state-dot"></i>
                  {{ state }}
                </el-tag>
                <i class="el-icon-arrow-down el-icon--right drop-angle"></i>
              </el-tag>
              <el-dropdown-menu slot="dropdown" class="dropbox">
                <el-dropdown-item command="1" class="droplist">
                  <img
                    src="../assets/icon-idle.png"
                    class="droplist-icon"
                  />空闲
                </el-dropdown-item>
                <el-dropdown-item command="2" class="droplist">
                  <img
                    src="../assets/icon-bussy.png"
                    class="droplist-icon"
                  />忙碌
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="user-welcome">
          <p class="user-name">
            {{ realName }}
          </p>
          <p class="welcome-text">你好，欢迎使用</p>
        </div>
        <div class="top-menu">
          <button @click="safeExit">安全退出</button>
        </div>
      </div>
    </header>
    <el-container class="chat-panel">
      <el-aside class="left-room-list">
        <el-tabs type="border-card" class="left-room-list-panel">
          <el-tab-pane :label="'会话列表 ' + convNum" class="tab-label">
            <RoomList
              ref="roomList"
              :rooms="rooms"
              :is-online-list="false"
              @enter="onEnter"
              @initChatView="initChatView"
            />
          </el-tab-pane>
          <el-tab-pane :label="'在线用户 ' + onLineNum" class="tab-label">
            <RoomList
              ref="roomList2"
              :rooms="rooms"
              :is-online-list="true"
              @enter="onEnter"
              @initChatView="initChatView"
            />
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-container class="right-chat-panel">
        <el-main>
          <MessageContent
            ref="msgContent"
            :editable-tabs="editableTabs"
            @select="selectTab"
            @updateContent="updateContent"
          />
        </el-main>
        <el-footer height="auto">
          <SendBox
            :room-name="roomName"
            :user-name="userName"
            :service-users="users"
            @image="onImage"
            @file="onFile"
            @video="onVideo"
            @talk="onTalk"
            @getServiceUsers="getServiceUsers"
            @changeServiceUsers="changeServiceUsers"
          />
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import RoomList from "@/components/RoomList";
import MessageContent from "@/components/MessageContent";
import SendBox from "@/components/SendBox";
import IM from "@/utils/im";

import { UploadFile } from "@/api/common";

import { beetlex, beetlexAction } from "@/utils/beetlex4axios";
import { getLocalStorage, removeLocalStorage } from "@/utils/local-storage";

export default {
  name: "App",
  components: {
    RoomList,
    MessageContent,
    SendBox,
  },
  data() {
    return {
      userName: "",
      realName: "",
      rooms: [],
      roomName: "",
      editableTabs: [],
      login: new beetlexAction("/Login"),
      getRoom: new beetlexAction("/Rooms"),
      enterRoom: new beetlexAction("/Enter"),
      exitRoom: new beetlexAction("/Exit"),
      chatLog: new beetlexAction("/ChatLog"),
      read: new beetlexAction("/Read"),
      talk: new beetlexAction("/Talk"),
      image: new beetlexAction("/Image"),
      file: new beetlexAction("/File"),
      changeState: new beetlexAction("/ChangeState"),
      serviceUsers: new beetlexAction("/ServiceUsers"),
      invite: new beetlexAction("/Invite"),
      hasLogin: false,
      serviceState: "1",
      users: [],
      timer: -1,
      title: "",
      isShine: false,
    };
  },
  computed: {
    state: function () {
      if (this.serviceState === "1") {
        return "空闲";
      } else if (this.serviceState === "2") {
        return "忙碌";
      } else {
        return "未知";
      }
    },
    stateType: function () {
      if (this.serviceState === "1") {
        return "success";
      } else if (this.serviceState === "2") {
        return "danger";
      } else {
        return "";
      }
    },
    onLineNum: function () {
      let ret = 0;
      for (var k = 0; k < this.rooms.length; k++) {
        // if (!this.rooms[i].lastMessage) {
        //     ret++;
        // }
        if (this.rooms[k].lastMessage) continue;
        for (let i = 0; i < this.rooms[k].onLine.length; i++) {
          if (this.rooms[k].onLine[i].type == 1) {
            ret++;
            break;
          }
        }
      }
      return ret;
    },
    convNum: function () {
      let ret = 0;
      for (var i = 0; i < this.rooms.length; i++) {
        if (this.rooms[i].lastMessage) {
          ret++;
        }
      }
      return ret;
    },
  },
  created() {
    var that = this;
    const { UserInfo, Authorization, SiteIds, RoomsInfo } = getLocalStorage(
      "UserInfo",
      "Authorization",
      "SiteIds",
      "RoomsInfo"
    );
    document.title = "在线聊天";
    // let initFirst= true;
    if (UserInfo) {
      let info = JSON.parse(UserInfo);
      info.access_token = Authorization;
      IM.init(info, SiteIds, () => {
        console.log("----- 初始化完成 IM -------");
        setTimeout(() => {
          if (this.rooms.length > 0) {
            // this.$refs.msgContent.addTab(this.rooms[0]);// 增加选项卡
            // this.$refs.roomList.selected(this.rooms[0]); // 增加选项卡
            // this.initChatView(this.rooms[0]);
          }
        }, 2500);
      });
      IM.eventRoomChange = () => {
        this.rooms = IM.rooms;
      };
      IM.Conn.AddListener("talk", (msg) => {
        this.onMsgTalk(msg);
      });
      IM.Conn.AddListener("image", (msg) => {
        this.onMsgTalk(msg);
      });
      IM.Conn.AddListener("file", (msg) => {
        this.onMsgTalk(msg);
      });
      IM.Conn.AddListener("video", (msg) => {
        this.onMsgTalk(msg);
      });
      IM.Conn.AddListener("audio", (msg) => {
        this.onMsgTalk(msg);
      });

      this.userName = info.uid.toString();
      this.realName = info.realname;
    }

    this.title = document.title;
    window.onfocus = function () {
      that.isShine = false;
    };
    window.onblur = function () {
      that.isShine = true;
    };
    // let that = this;
    // beetlex.websocket.connected = function() {
    //   that.$message({
    //     message: "服务已连接",
    //     type: "success"
    //   });
    //   that.onLogin();
    // };
    // beetlex.websocket.disconnect = function() {
    //   that.$message.error("服务连接已断开");
    // };
    // beetlex.websocket.receive = function(msg) {
    //   console.log(msg);

    //   if (msg.type === "read") {
    //     that.showRead(msg);
    //     return;
    //   }

    //   if (msg.type === "notify") {
    //     if (msg.history) return;

    //     that.$notify.info({
    //       title: "消息",
    //       message: msg.message
    //     });

    //     that.getRooms();
    //     return;
    //   }

    //   if (msg.type === "timeout") {
    //     that.$notify.info({
    //       title: "消息",
    //       message: "timeout"
    //     });
    //     return;
    //   }

    // 当聊天消息到来的时候，加红点、如果当前选显卡，回复已读（roomName = 当前选项卡）
    //   if (msg.type === "talk" || msg.type === "image" || msg.type === "file") {
    //     if (msg.user.name !== that.userName && !msg.history) {
    //       that.$refs.roomList.addBadge(msg.room);

    //       if (msg.room === that.roomName) {
    //         that.onRead(msg.room);
    //       }
    //     }
    //   }
    // 消息加到对应的选项卡里
    //   let tabs = that.editableTabs.filter(tab => tab.name === msg.room);
    //   if (tabs.length > 0) {
    //     if (!msg.history) {
    //       msg.read = false;
    //     }
    //     tabs[0].content.push(msg);
    //   }
    // };
    // beetlex.useWebsocket(this.$baseURL, Authorization);

    window.addEventListener("beforeunload", this.unLoadPage);
  },

  beforeDestroy() {
    this.unLoadPage();
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.unLoadPage);
  },
  methods: {
    initChatView(roomInfo) {
      let tabs = this.editableTabs.filter((tab) => tab.name === roomInfo.name);
      if (tabs.length > 0) {
        //重复进入
        this.$refs.msgContent.editableTabsValue = roomInfo.name;
        // this.onRead(roomInfo.name); // 增加已读状态

        this.roomName = roomInfo.name;
      } else {
        //第一次进入
        this.$refs.msgContent.addTab(roomInfo); // 增加选项卡
        this.roomName = roomInfo.name;
        // this.enterRoom.post({ room: roomInfo.name }).then(() => {// 进入房间
        //   let tabs = this.editableTabs.filter(tab => tab.name === roomInfo.name);
        //   // if (tabs.length > 0) {
        //   //   tabs[0].content = [];
        //   // }

        //   // this.chatLog.post({ room: roomName }).then(() => { // 拉历史消息
        //   //   // this.onTalk("已读");
        //   // });

        // });
        // debugger;
        IM.Conn.ChatLog(roomInfo.name, (dataChat) => {});
 

        //关闭其它Tab
        let tabs = this.editableTabs.filter((tab) => tab.name != roomInfo.name);
        for(var i in tabs){
          var name = tabs[i].name;
          this.$refs.msgContent.removeTab(name);
        }
      }
      let rs = this.rooms.filter((room) => room.name === roomInfo.name);
      if (rs.length > 0) {
        roomInfo.Badge = 0;
        rs[0] = roomInfo;
        rs[0].Badge = 0;
      }
      this.rooms = this.rooms.concat();
      // this.$refs.roomList.clearBadge(roomInfo);// 增加选项卡
      // roomInfo.Badge = "";
    },

    onMsgTalk(msg) {
      let that = this;
      // 当聊天消息到来的时候，加红点、如果当前选显卡，回复已读（roomName = 当前选项卡）
      if (
        msg.type === "talk" ||
        msg.type === "image" ||
        msg.type === "file" ||
        msg.type === "video"
      ) {
        if (msg.user.name !== this.userName && !msg.history) {
          // this.$refs.roomList.addBadge(msg.room);
          this.showNotification(this.title, msg.message);
          if (msg.room === this.roomName) {
            // this.onRead(msg.room);
          }
        }
      }
      // 消息加到对应的选项卡里
      let tabs = this.editableTabs.filter((tab) => tab.name === msg.room);
      if (tabs.length > 0) {
        if (!msg.history) {
          msg.read = false;
        }
        msg.dateF = IM.formatChatTime(msg.dateTime);

        msg.isMyMsg = true;//isMyMsg改为客服标记 false 为客服，true为访客
        if (msg.user == null) {
        } else {
          // if (msg.user.id === this.userName) {
          if (msg.user.id == this.roomName) {
            msg.isMyMsg = false;
          }

          if (msg.offSend == true) {
            msg.isMyMsg = false;
          }
        }
        // msg.isMyMsg = msg.user.name == this.userName;
        msg.Badge = "";
        tabs[0].content.push(msg);
        IM.Conn.Read(this.roomName, msg.msgId);
      }
    },

    getRooms() {
      this.getRoom.get().then((data) => {
        this.rooms = data;
      });
    },
    onLogin() {
      const { SiteIds } = getLocalStorage("SiteIds");
      this.login
        .post({
          name: this.userName,
          type: 2,
          serviceId: SiteIds,
        })
        .then(() => {
          if (!this.hasLogin) {
            this.hasLogin = true;
            this.getRooms();
            this.handleCommand("1");
          } else {
            this.editableTabs.forEach((tab) => {
              let roomName = tab.name;
              this.enterRoom.post({ room: roomName }).then(() => {
                tab.content = [];
                this.chatLog.post({ room: roomName });
              });
            });
          }
        });
    },

    showNotification(title, message) {
      let that = this;
      let timer;
      (function (timer) {
        let index = 0;
        clearInterval(timer);
        timer = setInterval(function () {
          if (that.isShine === false) {
            clearInterval(timer);
            document.head.getElementsByTagName("title")[0].innerHTML = title;
            return;
          }
          if (index % 2) {
            document.head.getElementsByTagName("title")[0].innerHTML =
              "[　　　　　　 ]" + title;
          } else {
            document.head.getElementsByTagName("title")[0].innerHTML =
              "[新消息]" + title;
          }
          index++;
          if (index > 20) {
            clearInterval(timer);
            document.head.getElementsByTagName("title")[0].innerHTML = title;
          }
        }, 500);
      })(timer);

      setTimeout(function () {
        if (window.Notification && Notification.permission !== "denied") {
          Notification.requestPermission(function (status) {
            if (status === "granted") {
              if (that.isShine === false) {
                return;
              }
              let n = new Notification(title, { body: message });
              n.onclick = function () {
                window.focus();
              };
            }
          });
        }
      }, 1000);
    },

    onEnter(roomName) {
      debugger;

      let tabs = this.editableTabs.filter((tab) => tab.name === roomName);
      if (tabs.length > 0) {
        //重复进入
        this.$refs.msgContent.editableTabsValue = roomName;
        // this.onRead(roomName);
      } else {
        //第一次进入
        this.$refs.msgContent.addTab(roomName); // 增加选项卡

        this.enterRoom.post({ room: roomName }).then(() => {
          // 进入房间
          let tabs = this.editableTabs.filter((tab) => tab.name === roomName);
          if (tabs.length > 0) {
            tabs[0].content = [];
          }

          this.chatLog.post({ room: roomName }).then(() => {
            // 拉历史消息
            // this.onTalk("已读");
          });
        });
      }
    },
    selectTab(roomName) {
      this.roomName = roomName;
      // Fixed Me 需要修复，增加已读标志位
      // this.onRead(roomName);
    },
    updateContent(c) {
      console.log(c);
      this.editableTabs = c.editableTabs;
      this.roomName = c.editableTabsValue;

      // this.exitRoom.post({ room: c.removeTab });
    },
    onImage(obj) {
      let data = new FormData();
      data.append("file", obj.file);
      let headers = { "Content-Type": "multipart/form-data" };
      UploadFile(data, headers, this.uploadStepProcess)
        .then((res) => {
          IM.Conn.Image(this.roomName, res);
        })
        .catch((err) => {
          console.log(err);
          this.uploadStep = 0;
        });

      // this.image.post({
      //   room: this.roomName,
      //   image: obj.image
      // });
    },
    uploadStepProcess() {
      // console.log()
    },
    onFile(obj) {
      let data = new FormData();
      data.append("file", obj.file);
      let headers = { "Content-Type": "multipart/form-data" };
      UploadFile(data, headers, this.uploadStepProcess)
        .then((res) => {
          let filename = res;
          IM.Conn.File(this.roomName, filename);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onVideo(obj) {
      let data = new FormData();
      data.append("file", obj.file);
      let headers = { "Content-Type": "multipart/form-data" };
      UploadFile(data, headers, this.uploadStepProcess)
        .then((res) => {
          let filename = res;
          IM.Conn.Video(this.roomName, filename);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onAudio(obj) {
      let data = new FormData();
      data.append("file", obj.file);
      let headers = { "Content-Type": "multipart/form-data" };
      UploadFile(data, headers, this.uploadStepProcess)
        .then((res) => {
          let filename = res;
          IM.Conn.Audio(this.roomName, filename);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onTalk(msg) {
      this.talk.post({
        room: this.roomName,
        message: msg,
      });
    },
    onRead(roomName) {
      if (this.$refs.roomList.clearBadge(roomName)) {
        // this.onTalk("已读");
        let tabs = this.editableTabs.filter((tab) => tab.name === roomName);
        if (tabs.length > 0) {
          let list = tabs[0].content || [];
          if (list.length > 0) {
            let msg = list[list.length - 1];
            this.read.post({ room: msg.room, msgId: msg.msgId });
          }
        }
      }
    },
    showRead(msg) {
      let tabs = this.editableTabs.filter((tab) => tab.name === msg.room);
      if (tabs.length > 0) {
        let message = tabs[0].content || [];
        let list = message.filter((m) => m.msgId === msg.message);
        if (list.length > 0) {
          list[0].read = true;
        }
      }
    },
    unLoadPage() {
      // beetlex.websocket.close();
      IM.Conn.closeConnByHand();
    },
    handleCommand(command) {
      var self = this;
      IM.Conn.ChangeState(command, function (data) {
        if (data) {
          self.serviceState = command;
          self.$message({
            message: "客服状态切换成功",
            type: "success",
          });
        } else {
          self.$message.error("客服状态切换失败");
        }
      });
      // this.changeState.post({ state: command }).then(data => {
      //   if (data) {
      //     this.serviceState = command;
      //     this.$message({
      //       message: "客服状态切换成功",
      //       type: "success"
      //     });
      //   } else {
      //     this.$message.error("客服状态切换失败");
      //   }
      // });
    },
    getServiceUsers() {
      this.serviceUsers.post({ state: 1 }).then((data) => {
        let list = [];
        for (let user of data) {
          if (user.name !== this.userName) {
            list.push(user);
          }
        }
        this.users = list;
      });
    },
    changeServiceUsers(obj) {
      this.invite.post(obj).then((data) => {
        if (data) {
          this.$message({
            message: "变更客服成功",
            type: "success",
          });
        } else {
          this.$message.error("变更客服失败");
        }
      });
    },
    safeExit() {
      removeLocalStorage("Authorization");
      removeLocalStorage("UserInfo");

      this.$router.push("login");
    },
    onBrShow() {
      let req =
        "https://im.jinlaijinwang.com:8443/api/IMAPI/GetVisitLog?uid=ZNcN1IZbz8&page=1&pageSize=10";
    },
  },
};
</script>

<style>
.el-footer {
  padding: 0;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
}

.el-main {
  color: #333;
  padding: 5px;
}

.el-container {
  min-width: 800px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.dropbox {
  background: rgba(54, 111, 225, 0.1);
  box-shadow: 0 0;
  border: 0;
}
.droplist {
  display: flex;
  align-items: center;
}
.droplist .droplist-icon {
  margin-right: 10px;
}
.drop-panel {
  background: 0 0;
  border: 0;
  display: flex;
  align-items: center;
}
.state-dot {
  width: 10px;
  height: 10px;
  background: #366ee1;
  display: block;
  border-radius: 10px;
  margin-right: 10px;
}
span.state-row.el-tag.el-tag--success {
  display: flex;
  align-items: center;
  background: 0 0;
  border: 0;
  color: #000;
}

span.state-row.el-tag.el-tag--danger {
  display: flex;
  align-items: center;
  background: 0 0;
  border: 0;
  color: #000;
}

span.state-row.el-tag.el-tag--danger i {
  background-color: orange;
}

.top-row .el-header {
  background: #fff;
  padding: 0 66px;
}
.user-sataus-panel {
  display: flex;
  align-items: center;
}
.user-sataus-panel .lf {
  margin-right: 15px;
  line-height: normal;
}
.user-sataus-panel .rt {
  display: flex;
  flex-direction: column;
}
.user-sataus-panel .rt .txt {
  font-size: 20px;
  color: #303030;
  line-height: 1;
  margin-bottom: 5px;
  padding-left: 5px;
}
.main-box {
  display: block;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 60px;
}
.state-row {
  padding: 0;
}
.user-welcome p {
  margin: 0;
}

.user-welcome {
  display: flex;
  align-items: center;
}

.user-welcome .user-name {
  background: #fafafa;
  color: #366ee1;
  padding: 8px 15px;
  border-radius: 5px;
  margin-right: 15px;
}

.user-welcome .welcome-text {
  color: #303030;
}

.top-menu button {
  border: 0;
  background: #366ee1;
  padding: 8px 19px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.top-menu button:hover {
  opacity: 0.8;
}
.chat-panel {
  padding: 24px;
  background: #f1f2f4;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  overflow-y: hidden;
}
.left-room-list {
  border-radius: 25px 25px 0 0;
  background: #fff;
  overflow: hidden;
}
.el-tabs--border-card {
  box-shadow: 0 0;
  height: 100%;
  box-sizing: border-box;
  border: 0;
}
#tab-0,
#tab-1 {
  width: 50%;
  border: 0;
  background: #c9ced5;
  color: #000;
}
#tab-0 {
  border-radius: 0 0 5px 0;
}
#tab-1 {
  border-radius: 0 0 0 5px;
}
.el-tabs__nav {
  width: 100%;
  background: #fff;
}
#tab-0.is-active,
#tab-1.is-active {
  background: #fff;
  border-radius: 0;
}
.el-main-room-list {
  padding: 0;
}
.room-item-btn {
  width: 100%;
}
.right-chat-panel {
  width: calc(100% - 330px);
  flex: 0 0 auto;
  margin-left: 30px;
  background: #ffffff;
  border-radius: 25px 25px 0 0;
  overflow: hidden;
}
.el-main {
  padding: 0;
  overflow: hidden;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
.left-room-list-panel .el-tabs__content {
  height: calc(100vh - 158px);
  box-sizing: border-box;
}
</style>
