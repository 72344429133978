import request from "@/utils/request";

export function UploadFile(data, headers, upload) {
  return request({
    url: "/UploadFile",
    method: "post",
    data: data,
    headers: headers,
    timeout: 60000,
    onUploadProgress: function(e) {
      if (upload) upload(e);
    }
  });
}