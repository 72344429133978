<template>
  <el-main class="el-main-room-list">
    <div v-for="room in list" :key="room.name" class="text item">
      <el-badge
        :name="room.name"
        type="primary"
        :class='isOnline(room)?"room-item":"room-item room-item2"'
        v-if="isShowRoom(room)"
      >
        <button
          type="button"
          class="el-button el-button--default el-button--small room-item-btn"
          @click="addTab(room)"
        >
     <img src="../assets/client.png" alt class="userpic" v-if="isOnline(room)"/>
                    <img src="../assets/client-b.png" alt class="userpic" v-if="!isOnline(room)"/>

          <div class="info">
            <div class="row1">
              <span class="desc">{{ room.description}}</span>
              <span class="unread"  v-if="room.Badge >= 1">新</span>
            </div>
            <div class="row2">
              <span class="messa">{{ getLastMessage(room)}}</span>
              <span class="date">{{ room.dateF}}</span>
            </div>
            <!-- <span style="display:block;text-align:left"> {{ room.Badge}} </span><br/> -->
          </div>
        </button>
      </el-badge>
    </div>
  </el-main>
</template>

<script>
import IM from "@/utils/im";
export default {
  name: "RoomList",
  components: {},
  props: {
    rooms: {
      type: Array,
      default: () => []
    },
    isOnlineList: Boolean
  },
  data() {
    return {
      list: []
    };
  },
  watch: {
    rooms: {
      // 数据变化时执行的逻辑代码
      handler(newValue, oldValue) {
        // debugger;
        this.list = newValue;
        // console.log('你修改了rooms对象(watch deep)', newValue, oldValue)t
      },
      // 开启深度监听
      deep: true
    }
  },
  methods: {
    addTab(roomInfo) {
      // debugger;
      this.selected(roomInfo);
      this.$emit("initChatView", roomInfo);
      // this.$emit("enter", roomName);
    },
    selected(roomInfo) {
      // debugger;
      // let rs = this.list.filter(room => room.name === roomInfo.name);
      // if (rs.length > 0) {
      //   rs[0].ButtonType = "success";
      // }
    },
    addBadge(roomInfo) {
      // let rs = this.list.filter(room => room.name === roomInfo.name);
      // if (rs.length > 0) {
      //   // rs[0].Badge++;
      // }
    },
    clearBadge(roomInfo) {
      // debugger;
      // let rs = this.list.filter(room => room.name === roomInfo.name);
      // if (rs.length > 0 ) {
      //   rs[0].Badge = "无";
      //   return true;
      // }
      // return false;
      // roomInfo.Badge = "";
    },
    getLastMessage(roomInfo) {
      let lastMsgContent = ""; // 显示出来的最后一条消息

      let lastMsg = "";
      let typeStr = {
        image: "[图片]",
        file: "[文件]",
        video: "[视频]",
        audio: "[音频]"
      };
      if (roomInfo.lastMessage) {
        let msgLast = roomInfo.lastMessage;
        if (msgLast.type != "talk") {
          lastMsg = typeStr[msgLast.type];
        } else {
          lastMsg = msgLast.message;
        }
      }
      let bUserOn = this.isOnline(roomInfo);
      roomInfo.createOn = bUserOn;
      if (roomInfo.creator == null) {
        lastMsgContent = "[找不到创建房间者] room.creator == null";
      } else if (roomInfo.onLine == null) {
        lastMsgContent = "[房间内没有在线用户] room.onLine == null";
      } else {
        if (!bUserOn) {
          lastMsgContent = "[用户离开] " + lastMsg; // 房间创建者不在房间里面, 刚离开
        } else {
          lastMsgContent = "[用户在线] " + lastMsg;
        }
      }
      lastMsgContent = ":" + lastMsgContent.slice(0, 11);

      return lastMsgContent;
    },
    isOnline(roomInfo) { 
      for (let i = 0; i < roomInfo.onLine.length; i++) {
        if (roomInfo.onLine[i].type == 1) {
          roomInfo.isClientOn = true;
          // console.log("Online");
          return true;
        }
      } 
      return false;
    },
    isShowRoom(roomInfo) {
      if (!this.isOnlineList) { // 会话列表(如果有最后一条消息,则就是会话列表)
        if (!!roomInfo.lastMessage) {
          return true;
        } else {
          return false;
        }
      } else { // 在线列表(如果有最后一条消息,则肯定不是在线列表, 循环判断是否有访客)
          if (!!roomInfo.lastMessage) return false;
          for (let i = 0; i < roomInfo.onLine.length; i++) {
            if (roomInfo.onLine[i].type == 1) {
              roomInfo.isClientOn = true;
              return true; 
            }
          }
          return false;
      }
    }
  }
};
</script>

<style>
.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.item {
  margin-bottom: 10px;
}
.item .room-item {
  width: 100%;
  background: #f3f5f8;
  overflow: hidden;
  border-radius: 7px;
  padding: 10px 10px 10px 20px;
  box-sizing: border-box;
}
.item .room-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background: #366ee1;
}

.item .room-item.room-item2::after{background:#a3a8ad}

.room-item-btn {
  background: 0 0;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: left;
}
.item .room-item .userpic {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  flex: 0 0 auto;
}
.item .room-item .info {
  overflow: hidden;
  line-height: 1.5;
  width: 100%;
}
.item .room-item .row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item .room-item .desc {
  font-weight: 600;
  color: #000;
}
.item .room-item .date {
  font-size: 12px;
  color: #bbbabf;
}
.item .room-item .unread {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  background: #366ee1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}
.item .room-item .messa {
  display: block;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 15px 10px;
}
</style>
