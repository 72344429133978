<template>
  <div>
    <el-dialog
      title="将用户封禁, 是否继续?"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <el-radio v-model="radio" label="1">黑名单</el-radio>
      <el-radio v-model="radio" label="2">封禁IP</el-radio>
      <div>
        <el-radio-group v-model="radio2" style="padding-top: 20px;">
          <el-radio-button label="1小时"></el-radio-button>
          <el-radio-button label="1天"></el-radio-button>
          <el-radio-button label="1月"></el-radio-button>
          <el-radio-button label="永久"></el-radio-button>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addBlackList">确 定</el-button>
        <el-button @click="centerDialogVisible = false">关 闭</el-button>
      </span>

    </el-dialog>
    <el-dialog
      title="解封用户?"
      :visible.sync="unBlockDialogVisible"
      width="550px"
      center>
        <el-table
          :data="lockData">
          <el-table-column
            label="封禁时间"
            width="230">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.date }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="封禁"
            width="180">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>封禁标识: {{ scope.row.name }}</p>
                <p>站点: {{ scope.row.desc }}</p>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium">{{ scope.row.name }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
             
              <el-button
                size="mini"
                type="danger"
                @click="unLockFunc(scope.$index, scope.row)">解封</el-button>
            </template>
          </el-table-column>
        </el-table>
    </el-dialog>
    <el-row class="send-option-list-row">
      <!--  <el-col v-if="roomName !== ''">
        <el-tag>{{ roomName }}</el-tag>
      </el-col>-->
      <el-col>
        <el-button  @click="showBlackList()" class="send-option-btn-item" style>
            <img src="../assets/client-c.png" title="解除封禁" />
        </el-button>
      </el-col>
      <el-col>
        <el-button  @click="showBliackDialog" class="send-option-btn-item" style>
            <img src="../assets/client-b.png" title="加入黑名单" />
        </el-button>
      </el-col>
      <el-col>
        <el-button @click="showEmojiPicker" class="send-option-btn-item">
          <img src="../assets/icon-emoji.png" title="发送表情" />
        </el-button>
        <VEmojiPicker v-if="show" :show-search="false" @select="selectEmoji" />
      </el-col>
      <el-col>
        <el-button @click="sendImage" class="send-option-btn-item">
          <img src="../assets/icon-img.png" title="发送图片" />
        </el-button>
        <input
          ref="image"
          class="file"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          @change="onImage"
        />
      </el-col>
      <el-col>
        <el-button @click="sendFile" class="send-option-btn-item">
          <img src="../assets/icon-file.png" title="发送文件" />
        </el-button>
        <input ref="file" class="file" type="file" accept="application/zip" @change="onFile" />
      </el-col>
      <el-col>
        <el-button @click="sendAudio" class="send-option-btn-item">
          <img src="../assets/icon-audio.png" title="发送音频" />
        </el-button>
        <input ref="audio" class="file" type="file" accept="audio/aac" @change="onAudio" />
      </el-col>
      <el-col>
        <el-button @click="sendVideo" class="send-option-btn-item">
          <img src="../assets/icon-media.png" title="发送视频" />
        </el-button>
        <input ref="video" class="file" type="file" accept="video/mp4" @change="onVideo" />
      </el-col>

      <el-col>
        <el-button  @click="brShow" class="send-option-btn-item" style>
            <img src="../assets/icon-history.png" title="历史记录" />
        </el-button>
      </el-col>
      <!-- <el-col>
        <el-button type="danger" icon="el-icon-delete" circle  @click="brShow" >
            
        </el-button>
      </el-col> -->
      <el-dialog title="浏览记录" :visible.sync="dialogTableVisible" width="1220px" style="padding: 0px 0px 10px;">
          <el-header :data="gridTitle" height="10px" >
              <el-link type="success" :href="gridTitle.domainname"  style="float: left; padding: 3px 0" target="_blank">{{gridTitle.domainname}}</el-link>
              <el-divider direction="vertical"></el-divider>
              <span>{{gridTitle.frombrowser}}</span>
              <el-button type="text" style="float: right; padding: 3px 0" @click="showMore">加载更多</el-button>
          </el-header>
          <el-divider></el-divider>
          <div style="max-height:600px;overflow:auto">
            <el-container>
              
               <el-main> 
                <el-table :data="gridBrData" :row-class-name="tableRowClassName">
                  <el-table-column width="180" property="visittime" label="时间">
                    <template slot-scope="scope">
                      <i class="el-icon-time"></i>
                      <span style="margin-left: 10px">{{ scope.row.dateF }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="300" property="title" label="访问地址">
                    <template slot-scope="props">
                      <el-link type="success" :href="props.row.url" target="_blank">{{props.row.title}}</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column width="150" property="timeStay" label="停留"></el-table-column>
                  
                  
                  <el-table-column width="250" property="frombrowser" label="终端详情">
                    <template slot-scope="props">
                    <el-tooltip :content="props.row.frombrowser" placement="bottom" effect="light">
                      <span>{{props.row.briefInfo}}</span>
                    </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column width="150" property="fromip" label="IP"></el-table-column>
                  <el-table-column width="150" property="fromcountry" label="地区"></el-table-column>
                </el-table>
              </el-main>
            </el-container>
          </div>
      </el-dialog>
      <!-- <el-col>
        <el-dropdown trigger="click" @command="handleCommand">
          <el-button  @click="getServiceUsers">
            变更客服<i class="el-icon-arrow-up el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="serviceUsers.length === 0">
              no service
            </el-dropdown-item>
            <el-dropdown-item
              v-for="user in serviceUsers"
              :key="user.name"
              :command="user.name"
            >
              {{ user.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>-->
    </el-row>
    <div class="send-text-panel">
      <textarea
        v-model="message"
        type="text"
        :rows="4"
        placeholder="请输入内容"
        class="send-text-area"
        @keyup.enter="sendMsg"
      ></textarea>
      <button @click="sendMsg" class="send-text-button">
        <img src="../assets/icon-send.png" title="发送消息" />
      </button>
    </div>
    <!-- <el-row>
      <el-input
        v-model="message"
        type="text"
        :rows="4"
        placeholder="请输入内容"
        class="send-text-panel"
        @keyup.enter.native="sendMsg"
      >
        <el-button slot="append" @click="sendMsg" class="send-text-button">
          <img src="../assets/icon-send.png" title="发送消息" />
        </el-button>
      </el-input>
    </el-row>-->
  </div>
</template>

<script>
import VEmojiPicker from "v-emoji-picker";
import UploadFile from "@/api/common";
import IM from "@/utils/im";
import request from "@/utils/request";
import platform from "@/utils/platform";
export default {
  name: "SendBox",
  components: {
    VEmojiPicker
  },
  props: {
    roomName: {
      type: String,
      default: ""
    },
    serviceUsers: {
      type: Array,
      default: () => []
    },
    userName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      dialogTableVisible:false,
      indexPage:1,
      centerDialogVisible: false,
      unBlockDialogVisible: false,
      message: "",
      gridBrData: [],
      radio: '1',
      radio2: '1小时',
      gridTitle:{},
      lockData:[]
    };
  },
  methods: {
    sendImage() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');return; 
      }
      this.$refs.image.dispatchEvent(new MouseEvent("click"));
    },
    onImage(e) {
      let file = e.target.files[0];
      if (file.size > 500 * 1024) {
        this.$alert("超过500K，用文件发送！", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.$emit("image", { file: file });
      this.$refs.image.value = "";
    },
    sendFile() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');return; 
      }
      this.$refs.file.dispatchEvent(new MouseEvent("click"));
    },

    onFile(e) {
      let file = e.target.files[0];
      if (file.size > 100 * 1024 * 1024) {
        this.$alert("文件发送最大100M！", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.$emit("file", { file: file });
      this.$refs.file.value = "";
    },
    sendVideo() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');return; 
      }
      this.$refs.video.dispatchEvent(new MouseEvent("click"));
    },
    onVideo(e) {
      let file = e.target.files[0];
      if (file.size > 100 * 1024 * 1024) {
        this.$alert("视频发送最大100M！", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.$emit("video", { file: file });
      this.$refs.video.value = "";
    },
    sendAudio() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');return; 
      }
      this.$refs.audio.dispatchEvent(new MouseEvent("click"));
    },
    onAudio(e) {
      let file = e.target.files[0];
      if (file.size > 100 * 1024 * 1024) {
        this.$alert("音频发送最大100M！", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.$emit("audio", { file: file });
      this.$refs.audio.value = "";
    },
    sendMsg() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口, 不能发送消息');
         return; 
      }
      var roomId = this.roomName;
      var message =  this.message;
      IM.Conn.Talk(this.roomName, this.message,function(msg){
        console.log(msg);
        if (msg.code && msg.code == 201) {
          if (msg.error && msg.error != "") {
              var msgSend = {
                dateTime: new Date().toISOString(),
                history: false,
                isMyMsg: true,
                message: message,
                msgId: msg.error,
                read: false,
                room: roomId,
                user:{type: 1},
                type: msg.url.replace("/","").toLowerCase(),
                offSend: true
              }
              console.log(msgSend);
              IM.Conn.OnServer(msgSend);
          }
        }
      });
      this.message = "";
    },
    showEmojiPicker() {
       if (this.roomName === "") {
         this.$message('没有聊天窗口');
         return; 
      }
      this.show = !this.show;
    },
    selectEmoji(emoji) {
      this.message += emoji.data;
      this.showEmojiPicker();
    },
    getServiceUsers() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');return; 
      }

      this.$emit("getServiceUsers");
      this.$refs.file.value = "";
    },
    
    brShow() {
      if (this.roomName === "") {
         this.$message('没有聊天窗口');
         return; 
      }
      this.dialogTableVisible = !this.dialogTableVisible
      this.indexPage = 1;
      let page = this.indexPage,
        size = 10;
      this.gridBrData = [];
      request({
        url: "https://im.jinlaijinwang.com:8443/api/IMAPI/GetVisitLog",
        method: "get",
        params: {
          uid: this.roomName,
          page: page,
          pageSize: size
        }
      }).then(data => {
        if (data) {
          var dateNow = new Date().getYear();
          for (var i = 0; i < data.length; i++) {
            var dd = new Date(data[i].visittime);
            var preStr = dd.getYear() == dateNow ? "":dd.getYear() + 1900 + "年";
            data[i].dateF = preStr + IM.formatChatTime(data[i].visittime);
            data[i].briefInfo = platform.parse(data[i].frombrowser).description;//this.uaMatch(data[i].frombrowser);
            data[i].timeStay = data[i].residencetime > 0?data[i].residencetime + "秒":"-";
            this.gridTitle.frombrowser = data[i].breifInfo;
            this.gridTitle.domainname =  data[i].domainname;
          }
          this.gridBrData = data;
        }
      }).catch(response => {});
      this.$emit("onBrShow");
    },
    showMore(){
      if (!this.roomName) {
        this.$message('没有更多浏览记录');
        return;
      }
      this.indexPage++;
      request({
        url: "https://im.jinlaijinwang.com:8443/api/IMAPI/GetVisitLog",
        method: "get",
        params: {
          uid: this.roomName,
          page: this.indexPage,
          pageSize: 10
        }
      }).then(data => {
        if (data) {
          if (data.length == 0) {
              this.$message('没有更多浏览记录');
          }
          var dateNow = new Date().getYear();
          for (var i = 0; i < data.length; i++) {
            var dd = new Date(data[i].visittime);
            var preStr = dd.getYear() == dateNow ? "":dd.getYear() + 1900 + "年";
            data[i].dateF = preStr + IM.formatChatTime(data[i].visittime);
            data[i].briefInfo = platform.parse(data[i].frombrowser).description;//this.uaMatch(data[i].frombrowser);
            data[i].timeStay = data[i].residencetime > 0?data[i].residencetime + "秒":"-";
            this.gridTitle.frombrowser = data[i].breifInfo;
            this.gridTitle.domainname =  data[i].domainname;
          }
          this.gridBrData = this.gridBrData.concat(data);
        }
      }).catch(response => {});
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
      return "";
    },
    handleCommand(name) {
      if (name) {
        this.$emit("changeServiceUsers", { name: name, room: this.roomName });
      }
    },
    uaMatch( ua ) {
     var tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
      if(/trident/i.test(M[1])){
          tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
          return {name:'IE',version:(tem[1]||'')};
          }   
      if(M[1]==='Chrome'){
          tem=ua.match(/\bOPR|Edge\/(\d+)/)
          if(tem!=null)   {return {name:'Opera', version:tem[1]};}
          }   
      M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      return "ssssssss " ;
    },
    open4() {
      var htmlIn = "<div style='height:600px;overflow:auto'> "+
"  <el-container> "+
"    <el-header :data='gridTitle' height='10px' > "+
"        <el-link type='success' :href='gridTitle.domainname' target='_blank'>{{gridTitle.domainname}}</el-link> "+
"        <el-divider direction='vertical'></el-divider> "+
"        <span>{{gridTitle.frombrowser}}</span> "+
"    </el-header> "+
"    <el-divider></el-divider> "+
"     <el-main>  "+
"      <el-table :data='gridBrData' :row-class-name='tableRowClassName'> "+
"        <el-table-column width='180' property='visittime' label='时间'> "+
"          <template slot-scope='scope'> "+
"            <i class='el-icon-time'></i> "+
"            <span style='margin-left: 10px'>{{ scope.row.dateF }}</span> "+
"          </template> "+
"        </el-table-column> "+
"        <el-table-column width='300' property='title' label='访问地址'> "+
"          <template slot-scope='props'> "+
"            <el-link type='success' :href='props.row.url' target='_blank'>{{props.row.title}}</el-link> "+
"          </template> "+
"        </el-table-column> "+
"        <el-table-column width='150' property='timeStay' label='停留'></el-table-column> "+
"        <el-table-column width='150' property='fromcountry' label='地区'></el-table-column> "+
"        <el-table-column width='150' property='fromip' label='IP'></el-table-column> "+
"        <el-table-column width='250' property='frombrowser' label='终端详情'> "+
"          <template slot-scope='props'> "+
"          <el-tooltip :content='props.row.frombrowser' placement='bottom' effect='light'> "+
"            <span>{{props.row.briefInfo}}</span> "+
"          </el-tooltip> "+
"          </template> "+
"        </el-table-column> "+
"      </el-table> "+
"    </el-main> "+
"  </el-container> "+
"</div> "
        this.$alert(htmlIn, 'HTML 片段', {
          dangerouslyUseHTMLString: true
        });
    },
    showBliackDialog()
    {
        if (this.roomName === "") {
            this.$message('请打开聊天窗口进行禁止操作');
            this.centerDialogVisible = false;
        } else {
            this.centerDialogVisible = true;
        }
    },
    addBlackList()
    {
        var self =  this;
        if (this.roomName === "") {
            this.$message('请打开聊天窗口进行禁止操作');
            this.centerDialogVisible = false;
            return; 
        }
        var refTime = {"1小时":1,"1天":24,"1月":30 * 24,"永久":99999 * 24};
        var timeLimit = refTime[this.radio2] * 3600;
        var isIp = this.radio == 1?false:true;

        IM.Conn.Blocking(this.roomName, isIp, timeLimit, function(data){
            if (isIp) {
                  self.$message(self.roomName + '已被禁止IP,时间:' + timeLimit + '秒');
            } else {
                self.$message(self.roomName + '已被加入黑名单,时间:' + timeLimit + '秒');
            }
            self.centerDialogVisible = false;
            IM.removeRoom(self.roomName);
        });
    },
    showBlackList()
    {
        var self = this;
        IM.Conn.BlackList(1, function(data){
            var arr = [];
            for (var i = 0; i < data.length; i++) {
                var siteLock = data[i].blackList;
                for(var key in siteLock) {
                    var dataRow = {};
                    dataRow["date"] = IM.formatChatTime(siteLock[key]);
                    dataRow["name"] = key;
                    dataRow["desc"] = data[i].serviceId;
                    arr.push(dataRow);
                }
            }
            self.lockData = arr;
            if (self.lockData.length > 0) {
                self.unBlockDialogVisible = true;
            } else {
                self.$message('没有封禁用户');
            }
        });
    },
    unLockFunc(index,row) {
        var self = this;
        IM.Conn.Unblocking(row.name,row.desc, function(data, res){
            if (res.code != 200) {
               self.$message(res.error);
               return;
            }
            self.$message(row.name + '解封成功');
            self.unBlockDialogVisible = false;
            
        })
    },
  }
};
</script>

<style>
.file {
  display: none;
}
#EmojiPicker {
  position: absolute;
  bottom: 100%;
  left: 0;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.send-option-list-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 60px;
  height: 60px;
  background: #f3f5f8;
}
.send-option-list-row .el-col-24 {
  width: auto;
}
.send-option-btn-item {
  border: 0;
  padding: 0;
  background: 0 0;
  margin-right: 20px;
  display: block;
}
.send-option-btn-item img {
  width: 25px;
  height: 25px;
}
.send-text-panel {
  position: relative;
}
.send-text-area {
  background: 0 0;
  border: 0;
  width: 100%;
  padding: 0px 120px 0px 60px;
  margin: 20px 0;
  outline: 0;
  box-sizing: border-box;
  resize: none;
  line-height: 1.5;
}
.send-text-button {
  background: linear-gradient(135deg, #40bdf7, #2671ff);
  padding: 0;
  height: 35px;
  width: 70px;
  border: 0;
  border-radius: 5px;
  position: absolute;
  bottom: 20px;
  right: 60px;
  cursor: pointer;
  outline: 0;
}
.send-text-button:hover {
  background: linear-gradient(-45deg, #40bdf7, #2671ff);
}
.send-text-button img {
  height: 20px;
}
</style>
